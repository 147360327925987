import axiosRetry from 'axios-retry';
import axios, { AxiosRequestConfig } from 'axios';

import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API, timeout: 10000 });

axiosRetry(axiosInstance, {
  retries: 10, // 재시도 횟수
  retryCondition: (error) =>
    // 재시도할 조건을 정의 (기본은 네트워크 에러와 5xx 에러)
    axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response?.status === 429,
  retryDelay: (retryCount) =>
    // 재시도 딜레이 설정 (기본은 점진적 지연)
    retryCount * 1000,
});

axiosInstance.interceptors.response.use(
  (res) => res,
  async (error) => {
    const {
      config,
      response: { status },
    } = error;
    // 오직 토큰 만료가 확실할 때만 아래의 내용을 시도
    if (status === 401) {
      const originalRequest = config;
      const refreshToken = await localStorage.getItem('refreshToken');
      // token refresh 요청
      const { data } = await axios.post(
        `${endpoints.auth.refresh}`, // token refresh api
        { refresh: refreshToken }
      );
      // 새로운 토큰 저장
      const { access, refresh } = data;
      sessionStorage.setItem('accessToken', access);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${access}`;
      localStorage.setItem('refreshToken', refresh);
      originalRequest.headers.authorization = `Bearer ${access}`;
      // 401로 요청 실패했던 요청 새로운 accessToken으로 재요청
      return axios(originalRequest);
    }

    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    whoami: '/api/v1/whoami',
    refresh: '/api/v1/token/refresh',
    login: '/api/v1/token/pair',
    preLogin: '/api/v1/pre-login',
    mfaLogin: '/api/v1/mfa-login',
    register: '/api/v1/register',
    social: {
      google: '/api/v1/accounts/google/login/',
      naver: '/api/v1/accounts/naver/login/',
      kakao: '/api/v1/accounts/kakao/login/',
      apple: '/api/v1/accounts/apple/login/',
    },
    request_code: `/api/v1/request-verification-code`,
    reset_password: `/api/v1/reset-password`,
    verify_email: `/api/v1/verify-email`,
    update_self: `/api/v1/update-self`,
    change_password: `/api/v1/change-password`,
    upload_profile: `/api/v1/upload-profile`,
  },
  profile: {
    update: '/api/v1/update-self',
    change_password: '/api/v1/change-password',
  },
  features: {
    autocomplete: '/api/v1/autocomplete',
    materials: '/api/v1/materials',
    machines_for_dashboard: '/api/v1/machines-for-dashboard',
    alerts: '/api/v1/alerts',
    unread_alerts_count: '/api/v1/unread-alerts-count',
    update_unread_alerts: '/api/v1/update-unread-alerts',
    orders: '/api/v1/orders',
    off_order_items: '/api/v1/off-order-items',
    crystal_report_dashboard: '/api/v1/crystal-report-dashboard',
    machine_schedule: '/api/v1/machine-schedule',
    machine_products: '/api/v1/machine-products',
    machine_with_schedule: (id: string | number) => `/api/v1/machine-with-schedules/${id}`,
    order_item_completion_status: (id: string | number) =>
      `/api/v1/order-item-completion-status/${id}`,
    order_item_production_completion: (id: string | number) =>
      `/api/v1/order-item-production-completion/${id}`,
    order_item_shipment_completion: (id: string | number) =>
      `/api/v1/order-item-shipment-completion/${id}`,
    order_shipment_completion: (id: string | number) => `/api/v1/order-shipment-completion/${id}`,
    cancel_order: (id: string | number) => `/api/v1/cancel-order/${id}`,
    cancel_order_item: (id: string | number) => `/api/v1/cancel-order-item/${id}`,
    update_order_dates: (id: string | number) => `/api/v1/update-order-dates/${id}`,
    dashboard_order_items: '/api/v1/dashboard-order-items',
    management_order_items: '/api/v1/management-order-items',
    change_order_item_date: '/api/v1/change-order-item-date',
    change_order_item_machine: '/api/v1/change-order-item-machine',
    change_order_item_quantity: '/api/v1/change-order-item-quantity',
    move_order_item: '/api/v1/move-order-item',
    put_order_item_memo: (id: string | number) => `/api/v1/order-item-memo/${id}`,
    reorder_order_items_of_machine: (id: string | number) =>
      `/api/v1/reorder-order-items-of-machine/${id}`,
    available_machines: (id: string | number) => `/api/v1/available-machines/${id}`,
    stocks: '/api/v1/stocks',
    stocks_by_product: (id: string | number) => `/api/v1/stocks-by-product/${id}`,
  },
  products: {
    list: `/api/v1/products`,
    create: `/api/v1/products`,
    delete: (id: string | number) => `/api/v1/products/${id}`,
    edit: (id: string | number) => `/api/v1/products/${id}`,
  },
  machines: {
    list: `/api/v1/machines`,
    create: `/api/v1/machines`,
    delete: (id: string | number) => `/api/v1/machines/${id}`,
    edit: (id: string | number) => `/api/v1/machines/${id}`,
    with_schedules: `/api/v1/machines-with-schedules`,
  },
  machineSchedules: {
    create: `/api/v1/machine-schedules`,
    delete: (id: string | number) => `/api/v1/machine-schedules/${id}`,
    edit: (id: string | number) => `/api/v1/machine-schedules/${id}`,
  },
  order: {
    request_order: `/api/v1/request-order`,
    request_off_order: `/api/v1/request-off-order`,
  },
  crystalReport: {
    read: '/api/v1/crystal-report',
    upload: '/api/v1/crystal-report',
    confirm: '/api/v1/crystal-report-confirm',
  },
};
